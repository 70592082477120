import React from 'react';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';

export default () => (
  <Layout>
    <Seo
      title="404 - Looks like you’re in the wrong place"
      description="There are more twists and turns on this website than in our brewery cellars. Go back to our home page!"
    />
    <Page type="error">
      <Header closeUrl="/lessons">NOT FOUND</Header>
      <Content center={true}>
        <h1 className="growing text-transform-uppercase">
          looks like you’re in&nbsp;the wrong <br />
          place
        </h1>
        <div className="page-text-container">
          <p className="h6">
            There are more twists and turns on this website than in
            our&nbsp;brewery cellars. <br />
            Go back to our home page!
          </p>
        </div>
      </Content>
      <Footer>
        <FooterLinkNext to="/lessons">back to homepage</FooterLinkNext>
      </Footer>
    </Page>
  </Layout>
)
